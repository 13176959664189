import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function cars(state = initialState.cars, action) {
  switch (action.type) {
    case types.SELECT_CAR_SUCCESS:
      const newState = { ...state, selectedCar: action.payload.data };
      return newState;
    case types.LOAD_CARS_SUCCESS:
      return action.payload;
    case types.DELETE_CAR_SUCCESS:
      return state.filter((car) => car.carId !== action.payload.data.carId);
    default:
      return state;
  }
}
