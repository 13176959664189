import moment from "moment-timezone";
import { transactionDateFormat } from "./Constants";

export const carFormIsValid = (car) => {
  const { registrationNumber, vehicleCategoryId, clientId } = car;
  const errors = {};
  if (!clientId) errors.clientId = "Alege un proprietar";

  if (!registrationNumber) errors.registrationNumber = "Completeaza nr de inmatriculare";

  if (!vehicleCategoryId) errors.vehicleCategoryId = "Alege categoria vehiculului";

  // Form is valid if the errors object still has no properties
  return errors;
};

export const clientFormIsValid = (client) => {
  const { name } = client;
  const errors = {};

  if (!name) errors.name = "Completeaza numele clientului";

  // Form is valid if the errors object still has no properties
  return errors;
};

export const stationFormIsValid = (station) => {
  const { name, description, identifier } = station;
  const errors = {};

  if (!name) errors.name = "Completeaza numele statiei";
  if (!description) errors.description = "Completeaza descrierea statiei";
  if (!identifier) errors.identifier = "Completeaza identificatorul statiei";

  // Form is valid if the errors object still has no properties
  return errors;
};

export const apppointmentFormIsValid = (appointment) => {
  const { inspectionCode, duration, carId, transactionDate, clientId, stationId } = appointment;
  const errors = {};
  if (!inspectionCode) errors.inspectionCode = "Specifica nr de serie folie";
  if (!duration) errors.duration = "Specifica o durata";
  if (!carId) errors.carId = "Alege o masina";
  if (!clientId) errors.clientId = "Alege un client";
  if (!stationId) errors.stationId = "Alege o statie";

  if (!transactionDate) errors.transactionDate = "Adauga data tranzactiei";
  if (!moment(transactionDate, transactionDateFormat).isValid())
    errors.transactionDate = "Selecteaza o data valida";

  // Form is valid if the errors object still has no properties
  return errors;
};

export const getFormattedDate = (date) => {
  const localeDate = date.toDate().toLocaleString("ro-RO");
  return moment.tz(localeDate, "DD.MM.YYYY, HH:mm:ss", "UTC").format("YYYY-MM-DD HH:mm:ss");
};
