import * as types from "../actions/actionTypes";
import initialState from "./initialState";

export default function clients(state = initialState.clients, action) {
  switch (action.type) {
    case types.SELECT_CLIENT_SUCCESS:
      const newState = { ...state, selectedClient: action.payload.data };
      return newState;
    case types.LOAD_CLIENTS_SUCCESS:
      return action.payload;
    default:
      return state;
  }
}
