import { CircularProgress, Grid } from "@mui/material";
import MDBox from "components/MDBox";
import React from "react";

function Spinner() {
  return (
    <MDBox mb={3} display="flex" justifyContent="center">
      <Grid container>
        <CircularProgress color="inherit" size={150} />
      </Grid>
    </MDBox>
  );
}

export default Spinner;
