import React from "react";
import { toast } from "react-toastify";
// Material Dashboard 2 PRO React components

import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import MDButton from "components/MDButton";
import DataTable from "examples/Tables/DataTable";
import { Grid, Icon } from "@mui/material";
import Swal from "sweetalert2";
import { withNavigation } from "custom/utils/hocs";
import { loadStations, deleteStation } from "../../api/stationApi";

class StationsPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      reloadTableData: false,
      alert: null,
      stations: [],
    };
  }

  getColumns = () => [
    {
      Header: "Nume",
      accessor: "name",
    },
    {
      Header: "Identificator",
      accessor: "identifier",
    },
    {
      Header: "Adresa",
      accessor: "address",
    },
    {
      Header: "Competente",
      accessor: "competences",
    },
    {
      Header: "Descriere",
      accessor: "description",
    },
  ];

  deleteStation = (stationId) => {
    this.setState({ alert: null }, () => {
      deleteStation(stationId)
        .then(() => {
          this.setState(
            {
              reloadTableData: !this.state.reloadTableData,
            },
            () => {
              toast.success("Station stears");
            }
          );
        })
        .catch((error) => {
          this.setState({
            loading: false,
            stationErrors: { onSave: error.message },
          });
        });
    });
  };

  deleteAlert = (stationId) => {
    Swal.fire({
      title: "Sunteti sigur?",
      type: "warning",
      text: "O data stearsa, statia nu va mai putea fi recuperata!",
      footer: "",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Da, sterge-l!",
      cancelButtonText: "Renunta",
      onOpen: () => {
        // code
      },
    }).then((result) => {
      // eslint-disable-next-line no-unused-vars
      const { isConfirmed, isDenied, isDismissed, dismiss, value } = result;
      if (value) {
        this.setState({ loading: true }, () => {
          this.deleteStation(stationId);
        });
      }
    });
  };

  fetchData = ({ pageSize, pageIndex, filters, sortBy }) => {
    this.setState({ loading: true });
    loadStations({ pageSize, pageIndex, filters, sortBy }).then((response) => {
      const { count, data, pageCount } = response;

      this.setState({
        stations: data,
        count,
        pageCount: pageCount || 0,
        loading: false,
      });
    });
  };

  onRowClick = ({ original: { stationId } }) => {
    this.props.navigate(`/admin/statii/${stationId}`);
  };

  render() {
    const { stations, loading, pageCount, count, reloadTableData } = this.state;

    return (
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox mb={3}>
          <Grid container>
            <Card>
              <Grid item xs={12} lg={12}>
                <MDBox display="flex" alignItems="center" justifyContent="space-between">
                  <MDBox p={3} lineHeight={1}>
                    <MDTypography variant="h5" fontWeight="medium">
                      Statii
                    </MDTypography>
                  </MDBox>
                  <MDBox display="flex">
                    <MDBox mr={2}>
                      <MDButton
                        variant="gradient"
                        color="error"
                        iconOnly
                        onClick={() => this.props.navigate(`/admin/statie/`)}
                      >
                        <Icon sx={{ fontWeight: "bold" }}>add</Icon>
                      </MDButton>
                    </MDBox>
                  </MDBox>
                </MDBox>
              </Grid>

              <MDBox mx={3}>
                <DataTable
                  table={{ columns: this.getColumns(), rows: stations }}
                  loading={loading}
                  fetchData={this.fetchData}
                  pageCount={pageCount}
                  count={count}
                  onClick={this.onRowClick}
                  forceReload={reloadTableData}
                />
              </MDBox>
            </Card>
          </Grid>
        </MDBox>
      </DashboardLayout>
    );
  }
}

export default withNavigation(StationsPage);
