export async function handleResponse(response) {
  return response.data;
  // if (response.status === 200) return response.data
  //   if (response.status === 400) {
  //     // So, a server-side validation error occurred.
  //     // Server side validation returns a string error message, so parse as text instead of json.
  //     const error = await response.text()
  //     throw new Error(error)
  //   }
  // throw new Error('Network response was not ok.')
}

// In a real app, would likely call an error logging service.
export function handleError(error) {
  let errorMessage = "A apărut o eroare neașteptată";

  if (error.response) {
    const { status, data } = error.response;

    if (data.error && data.error.includes("Duplicate")) {
      errorMessage = "Combinatia de date nu este unică. Aceste informații există deja în sistem.";
    } else if (status === 400) {
      errorMessage = data.message || "Cerere invalidă. Verificați datele trimise.";
    } else if (status === 401) {
      errorMessage = "Neautorizat. Vă rugăm să vă autentificați și să încercați din nou.";
    } else if (status === 403) {
      errorMessage = "Acces interzis. Nu aveți permisiunea de a efectua această acțiune.";
    } else if (status === 404) {
      errorMessage = "Resursa nu a fost găsită. Verificați URL-ul sau resursa pe care o căutați.";
    } else if (status === 500) {
      errorMessage = "Eroare internă a serverului. Vă rugăm să încercați din nou mai târziu.";
    } else {
      errorMessage = data.message || `Cererea a eșuat cu codul de eroare ${status}.`;
    }
  } else if (error.request) {
    errorMessage =
      "Nu există răspuns de la server. Verificați rețeaua sau încercați din nou mai târziu.";
  } else {
    errorMessage = error.message || "A apărut o eroare neașteptată.";
  }

  throw new Error(errorMessage);
}
