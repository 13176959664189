import { Autocomplete, CircularProgress } from "@mui/material";
import MDInput from "components/MDInput";
import React from "react";

function SelectInputPaginate({
  name,
  label,
  onChange,
  placeholder,
  value,
  loadOptions,
  error,
  success,
  ...rest
}) {
  const [open, setOpen] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const [callInProgress, setCallInProgress] = React.useState(false);
  const [page, setPage] = React.useState(0);
  const [currentSearch, setCurrentSearch] = React.useState("");

  const loading = open && callInProgress;

  React.useEffect(() => {
    if (!open) {
      setOptions([]);
      setPage(0);
    } else {
      async function fetchInitialData() {
        await handleChange(value?.label ?? "");
      }
      fetchInitialData();
    }
  }, [open]);

  const handleChange = async (search, newPage = page) => {
    setCallInProgress(true);
    const { options: optionsResponse, hasMore } = await loadOptions(search, undefined, {
      page: newPage,
    });
    setCallInProgress(false);

    setPage(newPage || 0);
    if (newPage === page + 1) {
      if (search !== currentSearch) {
        setOptions(optionsResponse);
      } else {
        setOptions([...options, ...optionsResponse]);
      }
    } else {
      setOptions(optionsResponse);
    }
    setCurrentSearch(search);
  };

  return (
    <Autocomplete
      name={name}
      open={open}
      onOpen={() => {
        setOpen(true);
        if (value && value.value) {
          handleChange(value.label);
        } else {
          // handleChange(""); too many records to load TODO: implement async again, but with material-ui autocomplete, or react-select async with material ui style
        }
      }}
      onClose={() => {
        setOpen(false);
      }}
      onChange={(ev, value) => onChange(value)}
      value={value}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      getOptionLabel={(option) => option.label}
      options={options}
      loading={loading}
      {...rest}
      ListboxProps={{
        sx: { maxHeight: "200px" },
        onScroll: (event) => {
          const listboxNode = event.currentTarget;
          if (listboxNode.scrollTop + listboxNode.clientHeight === listboxNode.scrollHeight) {
            handleChange(currentSearch, page + 1);
          }
        },
      }}
      renderInput={(params) => (
        <MDInput
          {...params}
          onChange={(ev) => {
            if (ev.target.value !== "" || ev.target.value !== null) {
              handleChange(ev.target.value);
            }
          }}
          label={label}
          placeholder={placeholder}
          error={!!error}
          helperText={error || ""}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? <CircularProgress color="inherit" size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
      getOptionKey={(option) => {
        return option.value;
      }}
    />
  );
}

export default SelectInputPaginate;
