/* eslint-disable no-unused-vars */
import React from "react";
import { toast } from "react-toastify";

// Material Dashboard 2 PRO React components
// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import { Grid } from "@mui/material";
import { withNavigation, withParams } from "../../utils/hocs";
import { carFormIsValid, apppointmentFormIsValid, clientFormIsValid } from "../../utils/Helper";
import { getAppointment, saveAppointment, deleteAppointment } from "../../api/appointmentApi";
import { loadVehicleCategories } from "../../api/vehicleCategoryApi";
import { loadCars, saveCar, deleteCar, getCar } from "../../api/carApi";
import { loadClients, saveClient, deleteClient, getClient } from "../../api/clientApi";

import { loadStations } from "../../api/stationApi";
import Spinner from "../common/Spinner";
import AppointmentForm from "./AppointmentForm";
import ClientForm from "../client/ClientForm";
import CarForm from "../car/CarForm";

export const newAppointment = {
  transactionDate: new Date(),
};
export const newCar = {};

class ManageAppointmentPage extends React.Component {
  constructor(props) {
    super(props);
    this.queryCarId = null;
    this.state = {
      car: {},
      appointment: {
        transactionDate: "",
      },
      client: {},
      loading: false,
      vehicleCategories: [],
      clients: [],
    };
  }

  componentDidMount() {
    this.fetchAppointment(this.props.params?.id);
  }

  componentDidUpdate(prevProps) {
    if (this.props.params?.id !== prevProps.params?.id) {
      this.fetchAppointment(this.props.params.id);
    }
  }

  fetchAppointment(appointmentId) {
    if (!appointmentId) return;

    this.setState({ loading: true }, () =>
      getAppointment(appointmentId)
        .then((response) => {
          const appointment = response.data;
          const car = appointment && appointment.car ? appointment.car : {};
          const client = appointment && appointment.client ? appointment.client : {};

          appointment.car = undefined;
          appointment.client = undefined;
          appointment.station = undefined;

          this.setState({
            appointment,
            car,
            client,
            loading: false,
          });
        })
        .catch((error) => {
          // alert('Loading appointments failed' + error)
        })
    );
  }

  loadCar = (carId) => {
    if (!carId) {
      this.setState({
        car: {},
        loading: false,
      });
      return;
    }
    getCar(carId).then((response) => {
      this.setState({
        car: response.data,
        loading: false,
      });
    });
  };

  loadClient = (clientId) => {
    if (!clientId) {
      this.setState({
        client: {},
        loading: false,
      });
      return;
    }
    getClient(clientId).then((response) => {
      this.setState({
        client: response.data,
        loading: false,
      });
    });
  };

  handleAppointmentChange = ({ name, value }) => {
    const { appointment } = this.state;
    switch (name) {
      case "car":
        this.setState(
          {
            appointment: {
              ...appointment,
              carId: value.value,
              carName: value.label,
            },
          },
          () => this.loadCar(value.value)
        );
        break;
      case "client": {
        this.setState(
          {
            appointment: {
              ...appointment,
              clientId: value.value,
              clientName: value.label,
            },
          },
          () => this.loadClient(value.value)
        );
        break;
      }
      case "station": {
        this.setState({
          appointment: {
            ...appointment,
            stationId: value.value,
            stationName: value.label,
          },
        });
        break;
      }
      default:
        this.setState({
          appointment: {
            ...appointment,
            [name]: value,
            loading: false,
          },
        });
        break;
    }
  };

  handleAppointmentSave = (event) => {
    const { appointment } = this.state;
    const { navigate } = this.props;
    const appointmentErrors = apppointmentFormIsValid(appointment);
    this.setState({ appointmentErrors });
    if (!(Object.keys(appointmentErrors).length === 0)) return;

    this.setState({ loading: true });

    const isNew = !appointment.appointmentId;
    saveAppointment(appointment)
      .then((response) => {
        const newAppointment = response.data;

        if (isNew) navigate(`/admin/programari/${newAppointment.appointmentId}`);
        else
          this.setState({
            appointment: newAppointment,
            loading: false,
          });

        toast.success("Programare salvata");
      })
      .catch((error) => {
        this.setState({
          loading: false,
          appointmentErrors: { onSave: error.message },
        });
      });
  };

  handleAppointmentDelete = () => {
    const {
      appointment: { appointmentId },
    } = this.state;

    if (!appointmentId) {
      return null;
    }

    const { navigate } = this.props;
    this.setState({ loading: true });
    deleteAppointment(appointmentId)
      .then(() => {
        this.setState({
          loading: false,
        });
        toast.success("Programare stearsa");
        navigate("/admin");
      })
      .catch((error) => {
        this.setState({
          loading: false,
          appointmentErrors: { onSave: error.message },
        });
      });
  };

  handleCarChange = ({ name, value }) => {
    const { car } = this.state;
    switch (name) {
      case "client": {
        this.setState({
          car: {
            ...car,
            clientId: value.value,
            clientName: value.label,
          },
          loading: false,
        });
        break;
      }
      case "multiClient": {
        const history = value.map(({ value, label }) => ({
          clientId: value,
          name: label,
        }));
        this.setState({
          car: {
            ...car,
            history,
          },
          loading: false,
        });
        break;
      }
      case "vehicleCategory":
        this.setState({
          car: {
            ...car,
            vehicleCategoryId: value.value,
            vehicleCategoryName: value.label,
          },
          loading: false,
        });
        break;
      default:
        this.setState({
          car: {
            ...car,
            [name]: value,
            loading: false,
          },
        });
        break;
    }
  };

  handleCarSave = (event) => {
    event.preventDefault();
    const { car, appointment } = this.state;

    const carErrors = carFormIsValid(car);
    this.setState({ carErrors });
    if (!(Object.keys(carErrors).length === 0)) return;

    this.setState({ loading: true });
    saveCar(car)
      .then((response) => {
        const newCar = response.data;
        this.setState({
          appointment: {
            ...appointment,
            carId: newCar.carId,
            carName: newCar.registrationNumber,
          },
          car: newCar,
          loading: false,
        });

        toast.success("Masina salvata");
      })
      .catch((error) => {
        this.setState({
          loading: false,
          carErrors: { onSave: error.message },
        });
      });
  };

  handleCarDelete = (event) => {
    event.preventDefault();
    const {
      car: { carId },
    } = this.state;

    if (!carId) {
      return null;
    }

    this.setState({ loading: true });

    deleteCar(carId)
      .then(() => {
        toast.success("Masina stearsa");

        this.handleAppointmentChange({
          name: "car",
          value: { car: {}, client: {}, vehicleCategory: {} },
        });
      })
      .catch((error) => {
        this.setState({
          loading: false,
          carErrors: { onSave: error.message },
        });
      });
  };

  handleClientChange = ({ name, value }) => {
    const { client } = this.state;
    this.setState({
      client: {
        ...client,
        [name]: value,
      },
    });
  };

  handleClientSave = (event) => {
    event.preventDefault();
    const { client, car, appointment } = this.state;

    const clientErrors = clientFormIsValid(client);
    this.setState({ clientErrors });
    if (!(Object.keys(clientErrors).length === 0)) return;
    this.setState({ loading: true });

    saveClient(client)
      .then((response) => {
        const newClient = response.data;
        this.setState({
          appointment: {
            ...appointment,
            clientId: newClient.clientId,
            clientName: newClient.name,
          },
          client: newClient,
          loading: false,
        });

        toast.success("Client salvat");
      })
      .catch((error) => {
        this.setState({
          clientErrors: { onSave: error.message },
          loading: false,
        });
      });
  };

  handleClientDelete = (event) => {
    event.preventDefault();
    const {
      client: { clientId },
    } = this.state;

    if (!clientId) {
      return null;
    }

    this.setState({ loading: true });

    deleteClient(clientId)
      .then(() => {
        toast.success("Client sters");
        this.handleCarChange({
          name: "client",
          value: { client: {} },
        });
      })
      .catch((error) => {
        this.setState({
          loading: false,
          clientErrors: { onSave: error.message },
        });
      });
  };

  loadCars = async (search, loadedOptions, { page }) => {
    const filters = [];

    if (search) filters.push({ id: "registrationNumber", value: search });

    const { data, pageCount } = await loadCars({
      pageSize: 10,
      pageIndex: page,
      filters,
    });

    const cars = [];

    for (const car of data) {
      const label = car.name ? `${car.registrationNumber} - ${car.name}` : car.registrationNumber;

      cars.push({
        value: car.carId,
        label,
      });
    }

    return {
      options: cars,
      hasMore: cars.length > 0,
      additional: {
        page: page + 1,
      },
    };
  };

  loadClients = async (search, loadedOptions, { page }) => {
    const filters = [];

    if (search) filters.push({ id: "name", value: search });

    const { data, pageCount } = await loadClients({
      pageSize: 10,
      pageIndex: page,
      filters,
    });

    const clients = [];

    for (const client of data) {
      const label = client.phoneNumber ? `${client.name} - ${client.phoneNumber}` : client.name;

      clients.push({ value: client.clientId, label });
    }

    return {
      options: clients,
      hasMore: clients.length > 0,
      additional: {
        page: page + 1,
      },
    };
  };

  loadVehicleCategories = async () => {
    const { data } = await loadVehicleCategories();

    const vehicleCategories = [];

    for (const vehicleCategory of data) {
      vehicleCategories.push({
        value: vehicleCategory.vehicleCategoryId,
        label: vehicleCategory.vehicleCategoryName,
      });
    }

    return {
      options: vehicleCategories,
      hasMore: false,
    };
  };

  loadStations = async (search, loadedOptions, { page }) => {
    const filters = [];

    if (search) filters.push({ id: "name", value: search });

    const { data, pageCount } = await loadStations({
      pageSize: 10,
      pageIndex: page,
      filters,
    });

    const stations = [];

    for (const station of data) {
      stations.push({ value: station.stationId, label: station.name });
    }

    return {
      options: stations,
      hasMore: stations.length > 0,
      additional: {
        page: page + 1,
      },
    };
  };

  render() {
    const { classes } = this.props;
    const {
      appointment,
      appointmentErrors,
      car,
      carErrors,
      client,
      clientErrors,
      loading,
      clients,
    } = this.state;

    return loading ? (
      <Spinner />
    ) : (
      <DashboardLayout>
        <DashboardNavbar />
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} sm={12} md={4}>
            <AppointmentForm
              appointment={appointment}
              errors={appointmentErrors}
              onChange={this.handleAppointmentChange}
              onSave={this.handleAppointmentSave}
              onDelete={this.handleAppointmentDelete}
              loadCars={this.loadCars}
              loadClients={this.loadClients}
              loadStations={this.loadStations}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <ClientForm
              client={client}
              errors={clientErrors}
              onChange={this.handleClientChange}
              onSave={this.handleClientSave}
              onDelete={this.handleClientDelete}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={4}>
            <CarForm
              car={car}
              errors={carErrors}
              onChange={this.handleCarChange}
              onSave={this.handleCarSave}
              onDelete={this.handleCarDelete}
              loadClients={this.loadClients}
              loadVehicleCategories={this.loadVehicleCategories}
            />
          </Grid>
        </Grid>
      </DashboardLayout>
    );
  }
}

export default withParams(withNavigation(ManageAppointmentPage));
