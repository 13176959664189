import React from "react";
import MDTypography from "components/MDTypography";
import { Card, Grid, Alert } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import Swal from "sweetalert2";
import TextInput from "../common/TextInput";

function StationForm({ station, onSave, onChange, onDelete, errors = {}, saving = false }) {
  const showDeleteAlert = () => {
    Swal.fire({
      title: "Sunteti sigur?",
      type: "warning",
      text: "O data stearsa, statia nu va mai putea fi recuperata!",
      footer: "",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Da, sterge-o!",
      cancelButtonText: "Renunta",
      onOpen: () => {
        // code
      },
    }).then((result) => {
      // eslint-disable-next-line no-unused-vars
      const { isConfirmed, isDenied, isDismissed, dismiss, value } = result;
      if (value) {
        onDelete();
      }
    });
  };

  return (
    <Card>
      {errors.onSave && (
        <MDBox mb={2}>
          <Alert severity="error" variant="filled">
            {errors.onSave}
          </Alert>
        </MDBox>
      )}
      <MDBox p={3} lineHeight={1}>
        <MDTypography variant="h5" fontWeight="medium">
          {station?.stationId ? "Editeaza statia" : "Adauga o statie nou"}
        </MDTypography>
      </MDBox>
      <MDBox component="form" pb={3} px={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextInput
              name="name"
              label="Nume"
              value={station.name}
              onChange={(event) => onChange(event.target)}
              error={errors.name}
            />
          </Grid>

          <Grid item xs={12}>
            <TextInput
              name="identifier"
              label="Identificator"
              value={station.identifier}
              onChange={(event) => onChange(event.target)}
              error={errors.identifier}
            />
          </Grid>

          <Grid item xs={12}>
            <TextInput
              name="description"
              label="Descriere"
              value={station.description}
              onChange={(event) => onChange(event.target)}
              error={errors.description}
            />
          </Grid>
        </Grid>
        <MDBox
          display="flex"
          justifyContent="space-between"
          alignItems="flex-end"
          flexWrap="wrap"
          mt={2}
        >
          {station.stationId && (
            <MDBox>
              <MDButton variant="gradient" color="dark" size="small" onClick={showDeleteAlert}>
                Sterge
              </MDButton>
            </MDBox>
          )}
          <MDBox ml="auto">
            <MDButton variant="gradient" color="dark" size="small" onClick={onSave}>
              {saving ? "Se salveaza..." : "Salveaza"}
            </MDButton>
          </MDBox>
        </MDBox>
      </MDBox>
    </Card>
  );
}

export default StationForm;
