import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

// Material Dashboard 2 PRO React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";

// Material Dashboard 2 PRO React page layout routes
import pageRoutes from "routes.public";

import bgImage from "assets/images/clint-mckoy.jpg";
import MDTypography from "components/MDTypography";
import { withNavigation } from "custom/utils/hocs";
import { Card } from "@mui/material";
import { connect } from "react-redux";

// eslint-disable-next-line no-unused-vars
function PublicRoute({ image, background, coverHeight, light, user, navigate, children }) {
  const routes = pageRoutes.map((route, index) => {
    // console.log("recomputing"); // TODO: do something about recomputing each time??
    if (index === 1) {
      if (user) {
        route.action = () => navigate("/admin");
        route.route = "/admin";
      } else {
        route.action = undefined;
        route.route = "/login";
      }
    }
    return route;
  });

  return (
    <MDBox
      width="100%"
      height="100%"
      minHeight="100vh"
      bgColor={background}
      sx={{ overflowX: "hidden" }}
    >
      <DefaultNavbar
        routes={routes}
        action={{
          type: "internal",
          route: "/admin/",
          label: "Sign In",
        }}
        transparent
        light
      />
      <MDBox
        position="relative"
        minHeight={coverHeight}
        height={coverHeight}
        borderRadius="xl"
        m={2}
        pt={1}
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { black } }) =>
            `${linearGradient(rgba(black.main, 0.25), rgba(black.main, 0.25))}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <Grid
          container
          spacing={3}
          justifyContent="center"
          sx={{ position: "relative", my: 13, textAlign: "center" }}
        >
          <Grid item xs={11} lg={5}>
            <MDBox mb={1}>
              <MDTypography variant="h2" color="white" fontWeight="bold">
                Statii ITP
              </MDTypography>
            </MDBox>
            <MDBox mb={2}>
              <MDTypography variant="body2" color="white" fontWeight="light">
                Management programari
              </MDTypography>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
      <Grid container sx={{ px: 2, my: 1 }} height="100%">
        <Grid item xs={12}>
          <Card sx={{ py: 2 }}>{children}</Card>
        </Grid>
      </Grid>
      <MDBox component="footer" py={6}>
        <Grid container justifyContent="center">
          <Grid item xs={10} lg={8}>
            <MDBox display="flex" justifyContent="center" flexWrap="wrap" mb={3}>
              <MDTypography
                component="a"
                href="#"
                variant="body2"
                fontWeight="regular"
                color="secondary"
              >
                Contact: +40 746 065 155
              </MDTypography>
            </MDBox>
          </Grid>
          <Grid item xs={12} lg={8} sx={{ textAlign: "center" }}>
            <MDTypography variant="body2" color="secondary">
              &copy; {new Date().getFullYear()} - Management programari Statii ITP
            </MDTypography>
          </Grid>
        </Grid>
      </MDBox>
    </MDBox>
  );
}

PublicRoute.defaultProps = {
  image: bgImage,
  background: "default",
  coverHeight: "30vh",
  light: false,
};

// Typechecking props for the BasicLayout
PublicRoute.propTypes = {
  light: PropTypes.bool,
  coverHeight: PropTypes.string,
  image: PropTypes.string,
  children: PropTypes.node.isRequired,
  background: PropTypes.oneOf(["white", "light", "default"]),
};

const mapStateToProps = (state) => ({
  user: state.user.user,
});

export default connect(mapStateToProps)(withNavigation(PublicRoute));
