import { configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import { thunk } from "redux-thunk"; // Correct import
import rootReducer from "./reducers";

// Middleware: Redux Persist Config
const persistConfig = {
  key: "root",
  storage,
  whitelist: ["user"], // Add specific reducers you want to persist
};

// Middleware: Redux Persist Persisted Reducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Redux: Store
const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: ["persist/PERSIST", "persist/REHYDRATE"], // Ignore redux-persist actions from being checked
      },
    }).concat(thunk),
  devTools: process.env.NODE_ENV !== "production", // Enable DevTools only in development
});

// Middleware: Redux Persist Persister
const persistor = persistStore(store);

export { store, persistor };
