import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/es/integration/react";
import App from "App";
import { Provider as ReduxProvider } from "react-redux";
// Material Dashboard 2 PRO React Context Provider
import { MaterialUIControllerProvider } from "context";
import moment from "moment-timezone";

import { store, persistor } from "./custom/redux/store";
import * as types from "./custom/redux/actions/actionTypes";
import client from "./custom/configs/client";
import "react-datetime/css/react-datetime.css";
import "react-toastify/dist/ReactToastify.css";
import "moment/locale/ro";

moment.locale("ro");

client.interceptors.response.use(
  (response) =>
    // Do something with response data
    response,
  (error) => {
    // Do something with response error
    if (error?.response?.status === 401) {
      store.dispatch({
        type: types.UNAUTHORIZED,
        payload: error,
      });
    }
    return Promise.reject(error);
  }
);

// Get the root element from the DOM
const container = document.getElementById("root");
const root = createRoot(container); // Create a root

// Render the app using createRoot
root.render(
  <ReduxProvider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <BrowserRouter>
        <MaterialUIControllerProvider>
          <App />
        </MaterialUIControllerProvider>
      </BrowserRouter>
    </PersistGate>
  </ReduxProvider>
);
