import React from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
// @material-ui/core components
import MDTypography from "components/MDTypography";
import { Card, Grid, Icon } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { getMe } from "custom/api/authApi";
import Spinner from "custom/components/common/Spinner";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import TextInput from "custom/components/common/TextInput";
import MDAvatar from "components/MDAvatar";
import { red } from "@mui/material/colors";
import { updatePasswordRedux, updateDetailsRedux } from "../redux/actions/userActions";

const emptyProfile = {
  email: "",
  name: "",
  phoneNumber: "",
  currentPassword: "",
  newPassword: "",
  newPasswordConfirmation: "",
  reminderMessage: "",
};

function UserProfilePage(props) {
  const [profile, setProfile] = React.useState(emptyProfile);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    getMe().then((response) => {
      const { email, name, phoneNumber, reminderMessage } = response?.data || {};
      setProfile({
        ...profile,
        email,
        name: name || "",
        phoneNumber: phoneNumber || "",
        reminderMessage: reminderMessage || "",
      });
    });
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setProfile({ ...profile, [name]: value });
  };

  const saveProfile = () => {
    const { name, phoneNumber, reminderMessage } = profile;
    setLoading(true);
    // eslint-disable-next-line no-unused-vars
    props.updateDetailsRedux({ name, phoneNumber, reminderMessage }).then((response) => {
      toast.success("Schimbarile au fost salvate!");
      setLoading(false);
    });
  };

  const savePassword = () => {
    // TODO: validate confirm password
    const { currentPassword, newPassword } = profile;
    // eslint-disable-next-line no-unused-vars
    props.updatePasswordRedux({ currentPassword, newPassword }).then((response) => {
      toast.success("Parola a fost schimbata!");
      setProfile({
        ...profile,
        currentPassword: "",
        newPassword: "",
        newPasswordConfirmation: "",
      });
    });
  };

  const passwordRequirements = [
    "One special characters",
    "Min 6 characters",
    "One number (2 are recommended)",
    "Change it often",
  ];

  const info = [
    "1. Unele telefoane/retele au un numar maxim de caractere per mesaj per mesaj. Aveti grija cu numarul de caractere folosite!!!",
    "2. Daca doriti sa adaugati numarul de inmatriculare sau data expirarii in mesaj adaugati urmatoarele texte:",
    "NR_INMATRICULARE",
    "DATA_EXPIRARE",
    "De ex mesajul:",
    "'ITP pentru autovehicul cu numarul NR_INMATRICULARE va expira la data DATA_EXPIRARE'",
    "va fi transformat automat pentru fiecare vehicul si data de expirare din sistem in:",
    "'ITP pentru autovehicul cu numarul OT00TXT va expira la data 2020-01-31'",
  ];

  const renderInfo = info.map((item, key) => {
    const itemKey = `info-${key}`;
    return (
      <MDBox key={itemKey} color="text" fontSize="1.25rem" lineHeight={1}>
        <MDTypography variant="button" color="text" fontWeight="regular" verticalAlign="middle">
          {item}
        </MDTypography>
      </MDBox>
    );
  });

  const renderPasswordRequirements = passwordRequirements.map((item, key) => {
    const itemKey = `element-${key}`;

    return (
      <MDBox key={itemKey} component="li" color="text" fontSize="1.25rem" lineHeight={1}>
        <MDTypography variant="button" color="text" fontWeight="regular" verticalAlign="middle">
          {item}
        </MDTypography>
      </MDBox>
    );
  });

  return (
    <div>
      {loading ? (
        <Spinner />
      ) : (
        <DashboardLayout>
          <DashboardNavbar />

          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={4}>
              <Card id="change-password">
                <MDBox p={3} lineHeight={1}>
                  <MDTypography variant="h5" fontWeight="medium">
                    Editeaza profilul - <small>Completeaza-ti profilul</small>
                  </MDTypography>
                </MDBox>
                <MDBox component="form" pb={3} px={3}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} md={12}>
                      <TextInput
                        label="Email"
                        fullWidth
                        disabled
                        name="email"
                        value={profile.email}
                        onChange={handleChange}
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12}>
                      <TextInput
                        label="Nume"
                        fullWidth
                        name="name"
                        value={profile.name}
                        onChange={handleChange}
                      />
                    </Grid>

                    <Grid item xs={12} sm={12} md={12}>
                      <TextInput
                        label="Telefon"
                        fullWidth
                        name="phoneNumber"
                        value={profile.phoneNumber}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                      <TextInput
                        label="Mesaj"
                        fullWidth
                        name="reminderMessage"
                        value={profile.reminderMessage}
                        onChange={handleChange}
                        multiline
                        rows={5}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                      <MDBox display="flex" alignItems="center">
                        <MDAvatar sx={{ bgcolor: red[500] }}>!</MDAvatar>
                        <MDBox ml={2}>
                          <MDTypography variant="h5" fontWeight="medium">
                            INFO
                          </MDTypography>
                          <MDBox display="flex" alignItems="flex-end">
                            <MDTypography variant="button" color="text">
                              Show less
                            </MDTypography>
                            <MDTypography variant="button" color="text" sx={{ lineHeight: 0 }}>
                              <Icon fontSize="small">expand_less</Icon>
                            </MDTypography>
                          </MDBox>
                        </MDBox>
                      </MDBox>
                      <MDBox ml={2} pl={6} pt={2} lineHeight={1}>
                        {renderInfo}
                      </MDBox>
                    </Grid>
                  </Grid>
                  <MDBox
                    display="flex"
                    justifyContent="space-between"
                    alignItems="flex-end"
                    flexWrap="wrap"
                    mt={2}
                  >
                    <MDBox ml="auto">
                      <MDButton
                        variant="gradient"
                        color="dark"
                        size="small"
                        onClick={() => saveProfile()}
                      >
                        Salveaza
                      </MDButton>
                    </MDBox>
                  </MDBox>
                </MDBox>
              </Card>
            </Grid>

            <Grid item xs={12} sm={12} md={4}>
              <Card id="change-password">
                <MDBox p={3}>
                  <MDTypography variant="h5">Change Password</MDTypography>
                </MDBox>
                <MDBox component="form" pb={3} px={3}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <TextInput
                        label="Parola curenta"
                        fullWidth
                        type="password"
                        name="newPassword"
                        value={profile.newPassword}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextInput
                        label="Parola noua"
                        fullWidth
                        type="password"
                        name="newPassword"
                        value={profile.newPassword}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextInput
                        label="Parola noua confirmare"
                        fullWidth
                        type="password"
                        name="newPasswordConfirmation"
                        value={profile.newPasswordConfirmation}
                        onChange={handleChange}
                      />
                    </Grid>
                  </Grid>
                  <MDBox mt={6} mb={1}>
                    <MDTypography variant="h5">Password requirements</MDTypography>
                  </MDBox>
                  <MDBox mb={1}>
                    <MDTypography variant="body2" color="text">
                      Please follow this guide for a strong password
                    </MDTypography>
                  </MDBox>
                  <MDBox
                    display="flex"
                    justifyContent="space-between"
                    alignItems="flex-end"
                    flexWrap="wrap"
                  >
                    <MDBox component="ul" m={0} pl={3.25} mb={{ xs: 8, sm: 0 }}>
                      {renderPasswordRequirements}
                    </MDBox>
                    <MDBox ml="auto">
                      <MDButton
                        variant="gradient"
                        color="dark"
                        size="small"
                        onClick={() => savePassword()}
                      >
                        update password
                      </MDButton>
                    </MDBox>
                  </MDBox>
                </MDBox>
              </Card>
            </Grid>

            {false && (
              <Grid item xs={12} sm={12} md={4}>
                <Card>
                  <MDBox p={3}>
                    <MDTypography variant="h5">Other </MDTypography>
                  </MDBox>
                  <MDBox component="form" pb={3} px={3}>
                    <Grid container spacing={3}>
                      <Grid item xs={12} />
                    </Grid>
                    <MDBox
                      display="flex"
                      justifyContent="space-between"
                      alignItems="flex-end"
                      flexWrap="wrap"
                    >
                      <MDBox ml="auto">
                        <MDButton
                          variant="gradient"
                          color="dark"
                          size="small"
                          onClick={() => console.log("clicked")}
                        >
                          other
                        </MDButton>
                      </MDBox>
                    </MDBox>
                  </MDBox>
                </Card>
              </Grid>
            )}
          </Grid>
        </DashboardLayout>
      )}
    </div>
  );
}

export default connect(null, { updatePasswordRedux, updateDetailsRedux })(UserProfilePage);
