import { combineReducers } from "redux";

// Imports: Reducers
import user from "./userReducer";
import vehicleCategories from "./vehicleCategoryReducer";
import appointments from "./appointmentReducer";
import cars from "./carReducer";
import clients from "./clientReducer";
import apiCallsInProgress from "./apiStatusReducer";

// Redux: Root Reducer
const rootReducer = combineReducers({
  user,
  vehicleCategories,
  appointments,
  cars,
  clients,
  apiCallsInProgress,
});

export default rootReducer;
