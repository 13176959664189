import client from "../configs/client";
import { handleResponse, handleError } from "./apiUtils";

export function login(credentials) {
  return client({ url: "auth/login", method: "POST", data: credentials })
    .then(handleResponse)
    .catch(handleError);
}

export function logout() {
  return client({ url: "auth/logout", method: "GET" }).then(handleResponse).catch(handleError);
}

export function getMe() {
  return client({ url: "auth/me", method: "GET" }).then(handleResponse).catch(handleError);
}

export function updateDetails(details) {
  return client({ url: "auth/updatedetails", method: "PUT", data: details })
    .then(handleResponse)
    .catch(handleError);
}

export function updatePassword(details) {
  return client({ url: "auth/updatePassword", method: "PUT", data: details })
    .then(handleResponse)
    .catch(handleError);
}
