import { Autocomplete } from "@mui/material";
import MDInput from "components/MDInput";
import React from "react";

function SelectInput({
  name,
  label,
  onChange,
  placeholder,
  value,
  error,
  success,
  options,
  // eslint-disable-next-line no-unused-vars
  ...rest
}) {
  return (
    <Autocomplete
      name={name}
      onChange={(ev, value) => onChange(value)}
      value={value}
      isOptionEqualToValue={(option, value) => option.value === value.value}
      getOptionLabel={(option) => option.label}
      options={options}
      {...rest}
      renderInput={(params) => (
        <MDInput
          {...params}
          onChange={(ev) => {
            if (ev.target.value !== "" || ev.target.value !== null) {
              onChange(ev.target.value);
            }
          }}
          label={label}
          placeholder={placeholder}
          error={!!error}
          helperText={error || ""}
        />
      )}
    />
  );
}

export default SelectInput;
