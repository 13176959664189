import React from "react";
import "./AppointmentsPage.css";
import { toast } from "react-toastify";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
// @material-ui/icons

import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import MDButton from "components/MDButton";
import { Close, Dvr } from "@mui/icons-material";
import DataTable, { DateColumnFilter, SelectColumnFilter } from "examples/Tables/DataTable";
import { Grid, Icon } from "@mui/material";
import Swal from "sweetalert2";
import { loadStations } from "../../api/stationApi";
import { loadAppointments, importAppointments, deleteAppointment } from "../../api/appointmentApi";
import { withNavigation } from "../../utils/hocs";
import SelectInput from "../common/SelectInput";

class AppointmentsPage extends React.Component {
  constructor(props) {
    super(props);
    this.inputOpenFileRef = React.createRef();

    const transactionDate = localStorage.getItem("transactionDate");
    const expirationDate = localStorage.getItem("expirationDate");

    this.state = {
      reloadTableData: false,
      alert: null,
      appointments: [],
      transactionDate: transactionDate && transactionDate !== "null" ? transactionDate : undefined,
      expirationDate: expirationDate && expirationDate !== "null" ? expirationDate : undefined,
      showSelectStation: false,
      station: null,
      stations: [],
    };
  }

  componentDidMount() {
    this.loadStations().then((result) => this.setState({ stations: result.options }));
  }

  uploadXlsx = (e) => {
    e.preventDefault();
    const stationId = this.state.station?.value;
    if (!stationId) return;
    const data = new FormData();
    data.append("file", e.target.files[0]);
    data.append("stationId", stationId);
    importAppointments(data)
      .then((response) => {
        this.setState(
          {
            reloadTableData: !this.state.reloadTableData,
            // loading: false,
          },
          () => {
            toast.success(`Ati adaugat ${response.count} de programari`);
          }
        );
      })
      .catch((err) => {
        JSON.stringify(err);
      });

    e.target.value = "";
  };

  getColumns = () => [
    {
      Header: "Statie",
      accessor: "stationName",
      Filter: SelectColumnFilter,
    },
    {
      Header: "Nr Inmatriculare",
      accessor: "registrationNumber",
    },
    {
      Header: "Nume",
      accessor: "name",
    },
    {
      Header: "Data tranzactiei",
      accessor: "transactionDate",
      Filter: DateColumnFilter,
    },
    {
      Header: "Data expirare",
      accessor: "expirationDate",
      Filter: DateColumnFilter,
    },
    {
      Header: "Nr telefon",
      accessor: "phoneNumber",
    },
    {
      Header: "Adresa",
      accessor: "address",
    },
    {
      Header: "Categorie vehicul",
      accessor: "vehicleCategoryName",
    },
    {
      Header: "Serie folie",
      accessor: "inspectionCode",
    },
    {
      Header: "CIV",
      accessor: "vic",
    },
    {
      Header: "Sasiu",
      accessor: "vin",
    },
    {
      Header: "Observatii",
      accessor: "description",
    },
    {
      Header: "Prestatie",
      accessor: "appointmentTypeName",
    },
  ];

  deleteAppointment = (appointmentId) => {
    this.setState({ alert: null }, () => {
      deleteAppointment(appointmentId)
        .then(() => {
          this.setState(
            {
              reloadTableData: !this.state.reloadTableData,
            },
            () => {
              toast.success("Programare stearsa");
            }
          );
        })
        .catch((error) => {
          this.setState({
            loading: false,
            appointmentErrors: { onSave: error.message },
          });
        });
    });
  };

  deleteAlert = (appointmentId) => {
    Swal.fire({
      title: "Sunteti sigur?",
      type: "warning",
      text: "O data stearsa, programarea nu va mai putea fi recuperata!",
      footer: "",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Da, sterge-o!",
      cancelButtonText: "Renunta",
      onOpen: () => {
        // code
      },
    }).then((result) => {
      // eslint-disable-next-line no-unused-vars
      const { isConfirmed, isDenied, isDismissed, dismiss, value } = result;
      if (value) {
        this.setState({ loading: true }, () => {
          this.deleteAppointment(appointmentId);
        });
      }
    });
  };

  fetchData = ({ pageSize, pageIndex, filters, sortBy }) => {
    this.setState({ loading: true });
    loadAppointments({ pageSize, pageIndex, filters, sortBy }).then((response) => {
      const { count, data, pageCount } = response;

      const appointments = data.map((appointment) => ({
        ...appointment,
        actions: (
          // we've added some custom button actions
          <div className="actions-right">
            {/* use this button to add a edit kind of action */}
            <MDButton
              justIcon
              round
              simple
              onClick={() => {
                this.props.navigate(`/programari/${appointment.appointmentId}`);
              }}
              color="warning"
              className="edit"
            >
              <Dvr />
            </MDButton>{" "}
            {/* use this button to remove the data row */}
            <MDButton
              justIcon
              round
              simple
              onClick={() => {
                const alert = this.deleteAlert(appointment.appointmentId);
                this.setState({ alert });
              }}
              color="error"
              className="remove"
            >
              <Close />
            </MDButton>{" "}
          </div>
        ),
      }));
      this.setState({
        appointments,
        count,
        pageCount: pageCount || 0,
        loading: false,
      });
    });
  };

  onRowClick = ({ original: { appointmentId } }) => {
    this.props.navigate(`/admin/programari/${appointmentId}`);
  };

  loadStations = async (search, loadedOptions, options) => {
    const filters = [];
    const page = options?.page || 0;

    if (search) filters.push({ id: "name", value: search });
    // eslint-disable-next-line no-unused-vars
    const { data, pageCount } = await loadStations({
      pageSize: 10,
      pageIndex: page,
      filters,
    });

    const stations = [];

    for (const station of data) {
      stations.push({ value: station.stationId, label: station.description });
    }

    return {
      options: stations,
      // hasMore: stations.length > 0,
      hasMore: false,
      additional: {
        page: page + 1,
      },
    };
  };

  importData = () => {
    this.setState({ showSelectStation: false }, () => this.inputOpenFileRef.current.click());
  };

  render() {
    const {
      appointments,
      loading,
      pageCount,
      count,
      transactionDate,
      expirationDate,
      reloadTableData,
      showSelectStation,
      stations,
    } = this.state;

    const selectStationDisabled = !this.state.station?.value;
    return (
      <DashboardLayout>
        <DashboardNavbar />
        <MDBox mb={3}>
          <Grid container>
            <Card>
              <Grid item xs={12} lg={12}>
                {showSelectStation && (
                  <Dialog
                    open={showSelectStation}
                    onClose={() => this.setState({ showSelectStation: false })}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                  >
                    <DialogTitle id="alert-dialog-title">Statie ITP</DialogTitle>
                    <DialogContent>
                      <DialogContentText id="alert-dialog-description">
                        Va rugam sa selectati statia pentru care veti importa datele
                      </DialogContentText>
                      <MDBox mx={3} mt={2} pb={10}>
                        <SelectInput
                          name="station"
                          label="Statie"
                          value={this.state.station}
                          placeholder="Selecteaza..."
                          options={stations}
                          onChange={(selectedValue) => this.setState({ station: selectedValue })}
                        />
                      </MDBox>
                    </DialogContent>
                    <DialogActions>
                      <MDButton
                        onClick={() => this.setState({ showSelectStation: false })}
                        variant="gradient"
                        color="error"
                      >
                        Renunta
                      </MDButton>
                      <MDButton
                        onClick={this.importData}
                        variant="gradient"
                        color="success"
                        disabled={selectStationDisabled}
                      >
                        Mai departe
                      </MDButton>
                    </DialogActions>
                  </Dialog>
                )}
                <MDBox display="flex" alignItems="center" justifyContent="space-between">
                  <MDBox p={3} lineHeight={1}>
                    <MDTypography variant="h5" fontWeight="medium">
                      Programari
                    </MDTypography>
                  </MDBox>
                  <MDBox display="flex">
                    <MDBox mr={2}>
                      <MDButton
                        variant="gradient"
                        color="success"
                        iconOnly
                        onClick={() => this.setState({ showSelectStation: true })}
                      >
                        <Icon sx={{ fontWeight: "bold" }}>file_upload</Icon>
                      </MDButton>
                    </MDBox>
                    <MDBox mr={2}>
                      <MDButton
                        variant="gradient"
                        color="error"
                        iconOnly
                        onClick={() => this.props.navigate(`/admin/programare/`)}
                      >
                        <Icon sx={{ fontWeight: "bold" }}>add</Icon>
                      </MDButton>
                    </MDBox>
                  </MDBox>
                </MDBox>
              </Grid>

              <MDBox mx={3}>
                <DataTable
                  table={{ columns: this.getColumns(), rows: appointments }}
                  loading={loading}
                  fetchData={this.fetchData}
                  pageCount={pageCount}
                  count={count}
                  onClick={this.onRowClick}
                  initialValues={{ transactionDate, expirationDate }}
                  forceReload={reloadTableData}
                  stations={stations || []}
                />
              </MDBox>
            </Card>
          </Grid>
          <input
            ref={this.inputOpenFileRef}
            type="file"
            onChange={this.uploadXlsx}
            style={{ display: "none" }}
          />
        </MDBox>
      </DashboardLayout>
    );
  }
}

export default withNavigation(AppointmentsPage);
