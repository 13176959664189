import client from "../configs/client";
import { handleResponse, handleError } from "./apiUtils";

export function getChartData({ fromDate, toDate }) {
  return client({
    url: `dashboard/chart`,
    method: "GET",
    params: { fromDate, toDate },
  })
    .then(handleResponse)
    .catch(handleError);
}

export function getStats() {
  return client({
    url: `dashboard/stats`,
    method: "GET",
  })
    .then(handleResponse)
    .catch(handleError);
}
