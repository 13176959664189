import client from "../configs/client";
import { handleResponse, handleError } from "./apiUtils";

export function loadVehicleCategories() {
  return client({ url: "vehicleCategories", method: "GET" })
    .then(handleResponse)
    .catch(handleError);
}

export function createVehicleCategory(data) {
  return client({
    url: "vehicleCategories",
    method: "POST",
    data,
  })
    .then(handleResponse)
    .catch(handleError);
}

export function updateVehicleCategory(vehicleCategoryId, data) {
  return client({
    url: `vehicleCategories/${vehicleCategoryId}`,
    method: "PUT",
    data,
  })
    .then(handleResponse)
    .catch(handleError);
}

export function deleteVehicleCategory(vehicleCategoryId) {
  return client({
    url: `vehicleCategories/${vehicleCategoryId}`,
    method: "DELTE",
  })
    .then(handleResponse)
    .catch(handleError);
}
