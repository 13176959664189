import React from "react";
import MDTypography from "components/MDTypography";
import { Card, Grid, Alert } from "@mui/material";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import moment from "moment-timezone";
import Swal from "sweetalert2";
import TextInput from "../common/TextInput";
import SelectInput from "../common/SelectInput";
import SelectInputPaginate from "../common/SelectInputPaginate";
import DateInput from "../common/DateInput";
import { itpDuration } from "../../utils/Constants";

function AppointmentForm({
  appointment,
  onSave,
  onChange,
  onDelete,
  saving = false,
  errors = {},
  loadCars,
  loadClients,
  loadStations,
}) {
  const getClientValue = () => {
    const { clientId, clientName } = appointment;
    if (!clientId) {
      return null;
    }

    return {
      value: clientId,
      label: clientName,
    };
  };

  const getCarValue = () => {
    const { carId, carName } = appointment;
    if (!carId) {
      return null;
    }

    return {
      value: carId,
      label: carName,
    };
  };

  const getStationValue = () => {
    const { stationId, stationName } = appointment;
    if (!stationId) {
      return null;
    }

    return {
      value: stationId,
      label: stationName,
    };
  };

  const showDeleteAlert = () => {
    Swal.fire({
      title: "Sunteti sigur?",
      type: "warning",
      text: "O data stearsa, programarea nu va mai putea fi recuperata!",
      footer: "",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Da, sterge-o!",
      cancelButtonText: "Renunta",
      onOpen: () => {
        // code
      },
    }).then((result) => {
      // eslint-disable-next-line no-unused-vars
      const { isConfirmed, isDenied, isDismissed, dismiss, value } = result;
      if (value) {
        onDelete();
      }
    });
  };

  return (
    <Card>
      {errors.onSave && (
        <MDBox mb={2}>
          <Alert severity="error" variant="filled">
            {errors.onSave}
          </Alert>
        </MDBox>
      )}
      <MDBox p={3} lineHeight={1}>
        <MDTypography variant="h5" fontWeight="medium">
          {appointment.appointmentId ? "Editeaza programarea" : "Adauga programare"}
        </MDTypography>
        {appointment.expirationDate && (
          <MDBox mt={1}>
            <MDTypography color="error">
              Data expirarii: {moment(appointment.expirationDate).format("Do MMMM YYYY")}
            </MDTypography>{" "}
          </MDBox>
        )}
      </MDBox>
      <MDBox component="form" pb={3} px={3}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <SelectInputPaginate
              name="station"
              label="Statie"
              value={getStationValue()}
              placeholder="Selecteaza..."
              loadOptions={loadStations}
              onChange={(selectedValue) =>
                onChange({
                  name: "station",
                  value: selectedValue || {},
                })
              }
              error={errors.stationId}
            />
          </Grid>

          <Grid item xs={12}>
            <SelectInputPaginate
              name="client"
              label="Client"
              value={getClientValue()}
              placeholder="Selecteaza..."
              loadOptions={loadClients}
              onChange={(selectedValue) =>
                onChange({
                  name: "client",
                  value: selectedValue || {},
                })
              }
              error={errors.clientId}
            />
          </Grid>

          <Grid item xs={12}>
            <SelectInputPaginate
              name="car"
              label="Masina"
              value={getCarValue()}
              placeholder="Selecteaza..."
              loadOptions={loadCars}
              onChange={(selectedValue) =>
                onChange({
                  name: "car",
                  value: selectedValue || {},
                })
              }
              error={errors.carId}
            />
          </Grid>

          <Grid item xs={12}>
            {" "}
            <TextInput
              name="inspectionCode"
              label="Serie folie"
              value={appointment.inspectionCode}
              onChange={(event) => onChange(event.target)}
              error={errors.inspectionCode}
            />{" "}
          </Grid>

          <Grid item xs={12}>
            <DateInput
              id="data_tranzactiei"
              value={appointment.transactionDate}
              label="Data tranzactiei"
              name="transactionDate"
              onChange={(date) => {
                onChange({ name: "transactionDate", value: date });
              }}
              error={errors.transactionDate}
            />{" "}
          </Grid>

          <Grid item xs={12}>
            <SelectInput
              name="itpDuration"
              label="Durata ITP"
              value={
                appointment.duration
                  ? {
                      value: appointment.duration,
                      label: `${appointment.duration} luni`,
                    }
                  : null
              }
              placeholder="Selecteaza..."
              options={itpDuration.map((duration) => ({
                value: duration,
                label: `${duration} luni`,
              }))}
              onChange={(selectedValue) =>
                onChange({
                  name: "duration",
                  value: selectedValue ? selectedValue.value : null,
                })
              }
              error={errors.duration}
            />
          </Grid>

          <Grid item xs={12}>
            <TextInput
              name="description"
              label="Observatii"
              value={appointment.description}
              onChange={(event) => onChange(event.target)}
              error={errors.description}
            />
          </Grid>
        </Grid>
        <MDBox
          display="flex"
          justifyContent="space-between"
          alignItems="flex-end"
          flexWrap="wrap"
          mt={2}
        >
          {appointment.appointmentId && (
            <MDBox>
              <MDButton variant="gradient" color="dark" size="small" onClick={showDeleteAlert}>
                Sterge
              </MDButton>
            </MDBox>
          )}
          <MDBox ml="auto">
            <MDButton variant="gradient" color="dark" size="small" onClick={onSave}>
              {saving ? "Se salveaza..." : "Salveaza"}
            </MDButton>
          </MDBox>
        </MDBox>
      </MDBox>
    </Card>
  );
}

export default AppointmentForm;
