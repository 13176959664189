import React from "react";
import "flatpickr/dist/flatpickr.css";
// react component plugin for creating a beautiful datetime dropdown picker
import MDInput from "components/MDInput";
import Flatpickr from "react-flatpickr";
import { Romanian } from "flatpickr/dist/l10n/ro";
import MDBox from "components/MDBox";
import { Clear } from "@mui/icons-material";

function DateInput({ name, label, onChange, value, error, dateFormat, enableTime, ...rest }) {
  return (
    <Flatpickr
      {...rest}
      onChange={([date], dateStr, instance) =>
        onChange(instance.formatDate(date, dateFormat || "Y-m-d H:i:S"))
      }
      options={{
        dateFormat: dateFormat || "Y-m-d H:i:S",
        enableTime,
        time_24hr: true,
        locale: Romanian,
      }}
      value={value}
      render={(props, ref) => (
        <MDInput
          {...props}
          label={label}
          fullWidth
          name={name}
          error={!!error}
          helperText={error || ""}
          inputRef={ref}
          InputProps={{
            ...props.InputProps,
            endAdornment: (
              // eslint-disable-next-line react/jsx-no-useless-fragment
              <>
                {value && (
                  <MDBox display="flex" style={{ cursor: "pointer" }} onClick={() => onChange("")}>
                    <Clear color="inherit" size={5} />
                  </MDBox>
                )}
              </>
            ),
          }}
        />
      )}
    />
  );
}

export default DateInput;
