import { beginApiCall, apiCallError } from "./apiStatusActions";
import * as types from "./actionTypes";
import * as authApi from "../../api/authApi";

export function loginSuccess(payload) {
  return { type: types.LOGIN_SUCCESS, payload };
}

export function logoutSuccess() {
  return { type: types.LOGOUT_SUCCESS };
}

export function updateDetailsSuccess(payload) {
  return { type: types.UPDATE_DETAILS_SUCCESS, payload };
}

export function updatePasswordSuccess(payload) {
  return { type: types.UPDATE_PASSWORD_SUCCESS, payload };
}

export function login(credentials) {
  return function (dispatch) {
    dispatch(beginApiCall());
    return authApi
      .login(credentials)
      .then((data) => {
        dispatch(loginSuccess(data));
      })
      .catch((error) => {
        dispatch(apiCallError(error));
        throw error;
      });
  };
}

export function updateDetailsRedux(details) {
  return function (dispatch) {
    dispatch(beginApiCall());
    return authApi
      .updateDetails(details)
      .then((data) => {
        dispatch(updateDetailsSuccess(data));
      })
      .catch((error) => {
        dispatch(apiCallError(error));
        throw error;
      });
  };
}

export function updatePasswordRedux(details) {
  return function (dispatch) {
    dispatch(beginApiCall());
    return authApi
      .updatePassword(details)
      .then((data) => {
        dispatch(updatePasswordSuccess(data));
      })
      .catch((error) => {
        dispatch(apiCallError(error));
        throw error;
      });
  };
}

export function logout() {
  return function (dispatch) {
    dispatch(beginApiCall());
    return authApi
      .logout()
      .then(() => {
        dispatch(logoutSuccess());
      })
      .catch((error) => {
        dispatch(apiCallError(error));
        throw error;
      });
  };
}
